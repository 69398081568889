import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import axios from "@axios";

export const groupReservationService = new GroupReservationService();
export default {
  data() {
    return {
      groups: [],
      group: {},
    };
  },
  created() {
    new Promise((resolve, reject) => {
      axios
        .get(`/groups`)
        .then((response) => {
          this.groups = response.data;
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
    if (this.$route.params.id) {
      new Promise((resolve, reject) => {
        axios
          .get(`/groups/${this.$route.params.id}`)
          .then((response) => {
            this.group = response.data;
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    }
  },
  methods: {
    noteReservation(reservation) {
      this.currentReservation = reservation;
      this.$nextTick(() => {
        this.$refs["res-note-modal"].$children[0].show();
      });
    },
    async submitReservationNote(reservation, reservation_note) {
      reservation.notes = reservation_note;
      const groupId =
        reservation.group_id !== undefined
          ? reservation.group_id
          : this.group.id;
      await groupReservationService.updateReservationNote(
        groupId,
        reservation.id,
        reservation_note
      );
    },
  },
};
