import axios from "@axios";
export default {
  data() {
    return {
      groups: [],
      group: {},
    };
  },
  created() {
    new Promise((resolve, reject) => {
      axios
        .get(`/groups`)
        .then((response) => {
          this.groups = response.data;
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
    if (this.$route.params.id) {
      new Promise((resolve, reject) => {
        axios
          .get(`/groups/${this.$route.params.id}`)
          .then((response) => {
            this.group = response.data;
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    }
  },
  methods: {
    normalizeString(value) {
      return String(value)
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    searchReservations(row, col, cellValue, searchTerm) {
      if (!searchTerm) {
        return true;
      }

      searchTerm = this.normalizeString(searchTerm);

      const term = this.normalizeString(searchTerm);

      const reservationNumber = this.normalizeString(row.reservation_number);
      const status = this.normalizeString(this.statusLabel(row.status));
      const packageName = this.normalizeString(this.getPackageName(row));
      const primaryContact = this.normalizeString(this.getPrimaryContact(row));
      const user = this.normalizeString(this.getUser(row.user_id));
      const groupName = this.normalizeString(
        row.group_name !== undefined ? row.group_name : this.group.name
      );

      const foundInTravelers = this.findInTravelers(row, searchTerm);
      const foundInOperatorsTour = this.findOperatorsTour(row, searchTerm);
      const dateDepartureGroup = this.findDateDepartureGroup(row, searchTerm);

      return (
        row.name.includes(term) ||
        status.includes(term) ||
        packageName.includes(term) ||
        primaryContact.includes(term) ||
        user.includes(term) ||
        groupName.includes(term) ||
        foundInTravelers ||
        dateDepartureGroup ||
        foundInOperatorsTour ||
        reservationNumber.includes(term)
      );
    },
    findInTravelers(reservation, searchTerm) {
      for (let i in reservation.package_reservations) {
        const element = reservation.package_reservations[i];
        const foundTraveler = element.travelers.find((traveler) => {
          const travelerName = `${traveler.first_name} ${traveler.last_name}`;
          if (this.normalizeString(travelerName).includes(searchTerm)) {
            return traveler;
          }
        });

        if (foundTraveler) {
          return true;
        }
      }

      return false;
    },
    findOperatorsTour(reservation, searchTerm) {
      for (let i in reservation.booking_numbers) {
        const element = reservation.booking_numbers[i];
        const operatorTour = `${element.operator}-${element.value}`;
        if (this.normalizeString(operatorTour).includes(searchTerm)) {
          return true;
        }
      }
      return false;
    },
    findAgency(reservation, searchTerm) {
      //
    },
    findDateDepartureGroup(reservation, searchTerm) {
      try {
        let group = this.groups.find((element) => {
          let group_id =
            reservation.group_id !== undefined
              ? reservation.group_id
              : this.group.id;
          return element.id === group_id;
        });
        let dateDeparture = group.departure_date;
        if (this.normalizeString(dateDeparture).includes(searchTerm)) {
          return true;
        } else {
          return false;
        }
      } catch {
        return false;
      }
    },
    getPackageName(reservation) {
      try {
        let package_name =
          reservation.package_reservations[0].group_package.name;
        return package_name;
      } catch (e) {
        return "Not selected";
      }
    },
    getPrimaryContact(reservation) {
      let reservationOwner = false;
      for (let i in reservation.package_reservations) {
        const element = reservation.package_reservations[i];
        reservationOwner = element.travelers.find((traveler) => {
          return traveler.reservation_owner === true;
        });

        if (reservationOwner) {
          break;
        }
      }

      if (reservationOwner) {
        return `${reservationOwner.first_name} ${reservationOwner.last_name}`;
      }
      return "N/A";
    },
  },
};
